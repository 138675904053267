import * as React from "react"
import { Helmet } from "react-helmet"

const defaultMetaTitle = "Hundreds of discounts across hundreds of brands!";
const defaultMetaDescription = "Signup today to receive hundreds of discounts at high street brands!";

const aruzySeo = (props) => {
    return (
        <Helmet>
            {props.title ? (<title>Aruzy | {props.title}</title>) : (<title>Aruzy | {defaultMetaTitle}</title>)}
            {props.description ? (<meta name="description" content={props.description}/>) : (<meta name="description" content={defaultMetaDescription}/>)}
        </Helmet>
	)
}

export default aruzySeo